function isDevelopment() {
	return process.env.NODE_ENV === "development";
}

export function getSanityConfig(dataset?: string) {
	const sanityDataset = dataset || (isDevelopment() ? "staging" : "production");

	return {
		projectId: "sczeoy4w",
		dataset: sanityDataset,
		apiVersion: "2022-03-07",
	};
}
