import { RefObject, useEffect, useState } from "react";
import { useMatches } from "react-router";
import type { RootLoaderData } from "~/root";
import type { PageData } from "~/types";
import { isPageData } from "~/utils/misc";

export const IS_BROWSER = typeof window !== "undefined";

export const BROWSER_SUPPORT = {
	IntersectionObserver:
		IS_BROWSER &&
		"IntersectionObserver" in window &&
		"IntersectionObserverEntry" in window &&
		"intersectionRatio" in window.IntersectionObserverEntry.prototype,
};

export function useLastMatchLoaderData<LoaderData>() {
	const matches = useMatches();

	if (!matches || matches.length === 0) return undefined;

	const match = matches[matches.length - 1];

	if (!match || !match.data) return undefined;

	return match.data as LoaderData;
}

function useMatchLoaderData<LoaderData>(handleId: string) {
	const matches = useMatches();

	const match = matches.find(({ id }) => id === handleId);

	if (!match) {
		throw new Error(`No active route has a handle ID of ${handleId}`);
	}

	return match.data as LoaderData;
}

export const useRootData = () => useMatchLoaderData<RootLoaderData>("root");

export function usePageLoaderData(): PageData | undefined {
	const matches = useMatches();

	if (!matches) return undefined;
	if (matches.length === 0) return undefined;

	// return last pageData found (from the deepest route)
	const matchWithPageData = matches
		.filter((match) => {
			const { data } = match;

			// check if data is an object and has pageData
			if (typeof data !== "object") return false;
			if (!data) return false;
			if (Array.isArray(data)) return false;
			if (!("pageData" in data)) return false;

			const { pageData } = data;

			// return true if pageData exists
			if (isPageData(pageData)) {
				return true;
			}

			return false;
		})
		.pop();

	if (!matchWithPageData) return undefined;

	const { pageData } = (matchWithPageData.data ?? {}) as {
		pageData: PageData;
	};

	return pageData;
}

export function usePageAvailableLocales() {
	const pageData = usePageLoaderData();
	const availableLocales = pageData ? pageData.availableLocales : [];

	return availableLocales;
}

export function useOnScreen(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ref: RefObject<any>,
	rootMargin = "0px"
) {
	const [isIntersecting, setIntersecting] = useState(false);

	useEffect(() => {
		if (!BROWSER_SUPPORT.IntersectionObserver) {
			return;
		}

		const el = ref.current;
		const observer = new IntersectionObserver(
			([entry]) => {
				setIntersecting(entry.isIntersecting);
			},
			{ rootMargin }
		);

		if (el) {
			observer.observe(el);
		}

		return () => {
			observer.unobserve(el);
		};
	}, [ref, rootMargin]);

	return isIntersecting;
}

export const useHash = (): string => {
	const [hash, setHash] = useState(() =>
		IS_BROWSER ? window.location.hash : ""
	);

	useEffect(() => {
		const onHashChange = () => setHash(window.location.hash);

		if (IS_BROWSER) {
			window.addEventListener("hashchange", onHashChange);
			return () => {
				window.removeEventListener("hashchange", onHashChange);
			};
		}
	}, []);

	return hash;
};

export function usePollingSelector<
	Element extends HTMLElement = HTMLDivElement,
>(
	selector: string,
	pollInterval = 500,
	maxAttempts = 5
): [Element | null, boolean] {
	const [element, setElement] = useState<Element | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		let attempts = 0;
		const intervalId = setInterval(() => {
			const foundElement = document.querySelector(selector) as Element | null;
			attempts++;

			if (foundElement) {
				setElement(foundElement);
				setIsLoading(false);
				clearInterval(intervalId);
			} else if (attempts >= maxAttempts) {
				setIsLoading(false);
				clearInterval(intervalId);
			}
		}, pollInterval);

		return () => clearInterval(intervalId);
	}, [selector, pollInterval, maxAttempts]);

	return [element, isLoading];
}
