// Reference:  https://github.com/Purii/react-use-scrollspy
import { useState, useEffect } from "react";
import { throttle } from "lodash-es";

export interface useScrollSpyParams {
	activeSectionDefault?: string;
	offsetPx?: number;
	sectionIds: Array<string>;
	throttleMs?: number;
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default ({
	activeSectionDefault = "",
	offsetPx = 0,
	sectionIds = [],
	throttleMs = 100,
}: useScrollSpyParams) => {
	const [activeSection, setActiveSection] = useState(activeSectionDefault);

	const handle = throttle(() => {
		let currentSectionId = activeSection;
		for (let i = 0; i < sectionIds.length; i++) {
			const sectionId = sectionIds[i];

			const section = document.getElementById(sectionId);

			// Needs to be a valid DOM Element
			if (!section || !(section instanceof Element)) {
				console.warn(
					`scroll-spy-nav: no element with id #${sectionId} present in the DOM`
				);
				continue;
			}

			// GetBoundingClientRect returns values relative to viewport
			if (section.getBoundingClientRect().top + offsetPx < 0) {
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				currentSectionId = section.getAttribute("id")!; // non-null assertion
				continue;
			}
			// No need to continue loop, if last element has been detected
			break;
		}

		setActiveSection(currentSectionId);
	}, throttleMs);

	useEffect(() => {
		window.addEventListener("scroll", handle);

		// Run initially
		handle();

		return () => {
			window.removeEventListener("scroll", handle);
		};
	}, [sectionIds, offsetPx]);
	return activeSection;
};
