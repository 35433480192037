import type { PageDocumentBase } from "~/types/sanity-schema";
import {
	isDevPageSingle,
	isPostSingle,
	isPageDocument,
} from "~/sanity/sanity-helpers";
import { format } from "date-fns/format";
import React from "react";
import { useLocation } from "react-router";
import { usePageLoaderData } from "~/hooks/common";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";
import { getCookieValue } from "~/utils/misc";
import type { AnalyticsId } from "~/utils/types";
import type { ValidationResult } from "~/components/tools/sql-syntax-checker/SyntaxCheckerForm";
import { SqlToolFeedbackSnowplowData } from "./sql-tools-user-feedback";
import { TrackingStep } from "~/components/tools/sql-optimizer/constants";

if (typeof window !== "undefined") {
	window.spTrackWebInteraction = spTrackWebInteraction;
}

export const getGTM = `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-KVSR9J7');
`;

export const getCookieConsent = `
  function OptanonWrapper() {
    if(window.OnetrustActiveGroups !== undefined) {
      if (window.OnetrustActiveGroups.includes('115') || window.OnetrustActiveGroups.includes('2')) {
        // user consent UX cookies 115 and Performance cookies 2, disable anonymous tracking
			if (window.snowplow) {
				window.snowplow('setCollectorUrl', 'dc.aiven.io')
				window.snowplow('disableAnonymousTracking', { stateStorageStrategy: 'cookieAndLocalStorage' });
			}
				window.pdt('setOptIn', true);
      } else {
        // enable fully anonymous tracking
			if (window.snowplow) {
				window.snowplow('clearUserData', { preserveSession: true });
			}
			window.pdt("revokeConsent");
			window.pdt("clearTrackingCookies");
      }

			if(typeof window.spTrackWebInteraction === "function") {
				window.spTrackWebInteraction({
					object: "consent",
					action: "given",
					value: window.OnetrustActiveGroups,
				});
			}

			var acceptBtns = ["onetrust-accept-btn-handler", "accept-recommended-btn-handler"];
			for (var i = 0; i < acceptBtns.length; i++) {
				var acceptBtn = document.getElementById(acceptBtns[i]);
				if (acceptBtn && !acceptBtn.hasClickListener) {
					acceptBtn.addEventListener("click", function() {
						window.spTrackWebInteraction({
							object: "consent button",
							action: "click",
							value: "Allow all",
						});
					});
					acceptBtn.hasClickListener = true;
				}
			}

			var customizeBtn = document.getElementById("onetrust-pc-btn-handler");
			if (customizeBtn && !customizeBtn.hasClickListener) {
				customizeBtn.addEventListener("click", function() {
					window.spTrackWebInteraction({
						object: "consent button",
						action: "click",
						value: "Customize settings",
					});
				});
				customizeBtn.hasClickListener = true;
			}

			var saveSettingBtns = document.getElementsByClassName("save-preference-btn-handler");
			for (let i = 0; i < saveSettingBtns.length; i++) {
				if (saveSettingBtns[i] && !saveSettingBtns[i].hasClickListener) {
					saveSettingBtns[i].addEventListener("click", function() {
						window.spTrackWebInteraction({
							object: "consent button",
							action: "click",
							value: "Save settings",
						});
					});
					saveSettingBtns[i].hasClickListener = true;
				}
			}
    }
  }
`;

export const getSnowplow = `
  ;(function(p,l,o,w,i,n,g){
  if(!p[i]){p.GlobalSnowplowNamespace=p.GlobalSnowplowNamespace||[];
  p.GlobalSnowplowNamespace.push(i);p[i]=function(){(p[i].q=p[i].q||[]).push(arguments)
  };p[i].q=p[i].q||[];n=l.createElement(o);g=l.getElementsByTagName(o)[0];n.async=1;
  n.src=w;g.parentNode.insertBefore(n,g)}
  }(
  window,
  document,
  "script",
  "https://storage.googleapis.com/aiven-dw-prod-snowplow-tracker/3.4.0/gh7rnaha.js",
  "snowplow")
  );

  if (typeof snowplow === "function") {
    snowplow("newTracker", "at", "dc.aiven.io", {
      appId: "aiven-io",
      platform: "web",
      forceSecureTracker: true,
      discoverRootDomain: true,
      cookieSameSite: 'Lax',
      anonymousTracking: { withSessionTracking: true, withServerAnonymisation: true },
      stateStorageStrategy: 'cookieAndLocalStorage',
      eventMethod: 'post',
      postPath: '/aiven/dc2',
      contexts: {
        webPage: true,
        gaCookies: true
      }
    });
  }
`;

export const getQualified = `
(function(w,q){w['QualifiedObject']=q;w[q]=w[q]||function(){
  (w[q].q=w[q].q||[]).push(arguments)};})(window,'qualified')
`;

export const getAccountEngagement = `
var piDomain = 'go.aiven.io';
(function() {
	var s=document.createElement('script');s.type='text/javascript';s.async=true;s.src='//'+piDomain+'/pdt.js';
	var c=document.getElementsByTagName('script')[0];c.parentNode.insertBefore(s,c);
	window['pdt'] = window['pdt'] || function(){(window['pdt'].cq = window['pdt'].cq || []).push(arguments);};
})();
pdt('create', 891043, 1090, piDomain);
`;

export const get6Sense = `
	window._6si = window._6si || [];
	window._6si.push(['enableEventTracking', true]);
	window._6si.push(['setToken', '6a2fbdf15d8723bd8572ef24cdee8631']);
	window._6si.push(['setEndpoint', 'b.6sc.co']);
	(function() {
		var gd = document.createElement('script');
		gd.type = 'text/javascript';
		gd.async = true;
		gd.src = '//j.6sc.co/6si.min.js';
		var s = document.getElementsByTagName('script')[0];
		s.parentNode.insertBefore(gd, s);
	})();
`;

function formatDate(date: string) {
	return format(new Date(date), "yyyy-MM-dd");
}

export function pageTracking(
	pageData: PageDocumentBase | undefined,
	featureFlagName?: string,
	experimentVariant?: string
) {
	if (!pageData || !window.snowplow) {
		return;
	}

	if (isPostSingle(pageData)) {
		const authorList = pageData?.authors
			? pageData?.authors.map((author) => author?.name)
			: [];
		const categoryList = pageData?.categories
			? pageData?.categories.map((category) => (category ? category.title : ""))
			: [];

		const { publishedAt, _updatedAt } = pageData;

		return window.snowplow("trackPageView", {
			context: [
				{
					schema: "iglu:io.aiven/content/jsonschema/1-0-0",
					data: {
						title: pageData?.title,
						authors: authorList,
						tags: categoryList,
						published_date: publishedAt ? formatDate(publishedAt) : "",
						updated_date: _updatedAt ? formatDate(_updatedAt) : "",
						language: pageData?.__i18n_lang,
					},
				},
			],
		});
	}

	if (isDevPageSingle(pageData)) {
		const tagList = pageData?.tags
			? pageData?.tags.map((tag) => (tag ? tag.title : ""))
			: [];
		const internalTagList = pageData?.internalTags
			? pageData?.internalTags.map((internalTag) =>
					internalTag ? internalTag.title : ""
				)
			: [];

		const { publishedAt, _updatedAt } = pageData;

		return window.snowplow("trackPageView", {
			context: [
				{
					schema: "iglu:io.aiven/content/jsonschema/1-0-0",
					data: {
						title: pageData?.title,
						tags: tagList,
						internal_tags: internalTagList,
						featured: pageData?.feature?.active,
						published_date: publishedAt ? formatDate(publishedAt) : "",
						updated_date: _updatedAt ? formatDate(_updatedAt) : "",
						language: pageData?.__i18n_lang,
					},
				},
			],
		});
	}

	if (isPageDocument(pageData) && (pageData?.tags || pageData?.internalTags)) {
		const tagList = pageData?.tags
			? pageData?.tags.map((tag) => (tag ? tag.title : ""))
			: [];
		const internalTagList = pageData?.internalTags
			? pageData?.internalTags.map((internalTag) =>
					internalTag ? internalTag.title : ""
				)
			: [];

		return window.snowplow("trackPageView", {
			context: [
				{
					schema: "iglu:io.aiven/content/jsonschema/1-0-0",
					data: {
						tags: tagList,
						internal_tags: internalTagList,
					},
				},
			],
		});
	}

	// In getCookieConsent, if user consent (OneTrust), allow Pardot tracking by setting the cookies: pi_opt_in to true and set cookies unique visitor ID: visitor_id<accountid>
	const VISITOR_ID = "visitor_id890043";

	const pardotVisitorId = getCookieValue(VISITOR_ID);

	const contextEntity = [];

	if (pardotVisitorId) {
		contextEntity.push({
			schema: "iglu:io.aiven/pardot/jsonschema/1-0-1",
			data: {
				pardot_id: pardotVisitorId,
			},
		});
	}

	if (featureFlagName && experimentVariant) {
		const featureFlagContext = spTrackFeatureFlag({
			featureFlagName,
			experimentVariant,
		});
		contextEntity.push(featureFlagContext);
	}

	if (contextEntity.length > 0) {
		window.snowplow("clearGlobalContexts");
		window.snowplow("addGlobalContexts", contextEntity);
	}

	window.snowplow("trackPageView");

	spTrackQualified();
	spTrackAccountEngagement();
}

export function spTrackAccountEngagement() {
	if (!window.pdt) return;

	return window.pdt("sendPageView");
}

export function spTrackFilter(filters: string[]) {
	if (!window.snowplow) return;

	return window.snowplow("trackSelfDescribingEvent", {
		event: {
			schema: "iglu:io.aiven/search/jsonschema/1-0-0",
			data: {
				filters: filters,
			},
		},
	});
}

export function spTrackSearchTerm(term: string) {
	if (!window.snowplow) return;

	return window.snowplow("trackSelfDescribingEvent", {
		event: {
			schema: "iglu:io.aiven/search/jsonschema/1-0-0",
			data: {
				terms: term,
			},
		},
	});
}

export function usePageTracking({
	locale,
	featureFlagName,
	experimentVariant,
}: {
	locale: string;
	featureFlagName?: string;
	experimentVariant?: string;
}) {
	const location = useLocation();
	const pageData = usePageLoaderData();

	const pageContent = pageData?.data;

	// Snowplow
	useDeepCompareEffectNoCheck(() => {
		if (window.snowplow) {
			window.snowplow("enableActivityTracking", {
				minimumVisitLength: 10,
				heartbeatDelay: 10,
			});

			pageTracking(pageContent, featureFlagName, experimentVariant);
		}
	}, [pageContent]);

	// User-agent Client Hints for user finger printing on initial page load
	React.useEffect(() => {
		if (window.snowplow) {
			window.snowplow(
				"addPlugin",
				"https://cdn.jsdelivr.net/npm/@snowplow/browser-plugin-client-hints@latest/dist/index.umd.min.js",
				["snowplowClientHints", "ClientHintsPlugin"]
			);
		}
	}, []);

	React.useEffect(() => {
		// For GTM trigger used in other marketing tags
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "website_pageview",
		});

		// Qualified register page change for experience to excecute
		if (window.qualified) {
			window.qualified("page");
		}
	}, [locale, location.pathname, location.search]);
}

export function spTrackWebInteraction({
	object,
	action,
	value,
}: {
	object: string;
	action: string;
	value: string;
}) {
	if (!window.snowplow) return;

	return window.snowplow("trackSelfDescribingEvent", {
		event: {
			schema: "iglu:io.aiven/web_interaction/jsonschema/1-0-0",
			data: {
				object: object,
				action: action,
				value: value,
			},
		},
	});
}

export function spTrackPardotForm({
	formUrl,
	formType,
}: {
	formUrl: string;
	formType: string;
}) {
	if (!window.snowplow) return;

	return window.snowplow("trackSelfDescribingEvent", {
		event: {
			schema: "iglu:io.aiven/web_interaction/jsonschema/1-0-0",
			data: {
				object: "form",
				action: "submit",
				value: formType,
			},
		},
		context: [
			{
				schema: "iglu:io.aiven/pardot/jsonschema/1-0-1",
				data: {
					pardot_form: formUrl,
				},
			},
		],
	});
}

export function trackQueryOptimization({
	analyticsId,
	source,
	optimizerStep,
	databaseType,
	databaseVersion,
	isSampleQuery,
	isQueryValid = null,
	isMetadataValid = null,
	recommendationsCount = null,
	error = "",
}: {
	analyticsId: string;
	source: string;
	databaseType: string;
	databaseVersion: string;
	optimizerStep: TrackingStep;
	isSampleQuery: boolean;
	isQueryValid?: boolean | null;
	isMetadataValid?: boolean | null;
	recommendationsCount?: number | null;
	error?: string;
}): void {
	if (!window.snowplow) return;

	return window.snowplow("trackSelfDescribingEvent", {
		event: {
			schema: "iglu:io.aiven/web_interaction/jsonschema/1-0-0",
			data: {
				object: analyticsIdF(analyticsId, "query-optimizer"),
				action: "optimize-sql-query",
			},
		},
		context: [
			{
				schema: "iglu:io.aiven/tools_query_optimizer/jsonschema/1-0-0",
				source: source,
				optimizer_step: optimizerStep,
				is_sample_query: isSampleQuery,
				is_query_valid: isQueryValid,
				is_metadata_valid: isMetadataValid,
				recommendations_count: recommendationsCount,
				error_message: error,
			},
			{
				schema: "iglu:io.aiven/tools_db_selection/jsonschema/1-0-0",
				data: {
					database_type: databaseType,
					database_version: databaseVersion,
				},
			},
		],
	});
}

export function trackSyntaxCheck({
	analyticsId,
	error,
	databaseType,
	databaseVersion,
	validationResult,
	queryLength,
	isSelectQuery,
}: {
	analyticsId: string;
	error?: string;
	databaseType: string;
	databaseVersion: string;
	validationResult?: ValidationResult;
	queryLength: number;
	isSelectQuery: boolean;
}): void {
	if (!window.snowplow) return;

	return window.snowplow("trackSelfDescribingEvent", {
		event: {
			schema: "iglu:io.aiven/web_interaction/jsonschema/1-0-0",
			data: {
				object: analyticsIdF(analyticsId, "validation"),
				action: "validate-sql-query",
			},
		},
		context: [
			{
				schema: "iglu:io.aiven/tools_sql_syntax_check/jsonschema/1-0-0",
				data: {
					query_length: queryLength,
					is_select_query: isSelectQuery,
					query_valid: Boolean(validationResult?.valid),
					error_message: error ? error : "",
				},
			},
			{
				schema: "iglu:io.aiven/tools_db_selection/jsonschema/1-0-0",
				data: {
					database_type: databaseType,
					database_version: databaseVersion,
				},
			},
		],
	});
}

export function trackPgPlayground({
	analyticsId,
	queryLength,
}: {
	analyticsId: string;
	queryLength: number;
}): void {
	if (!window.snowplow) return;

	return window.snowplow("trackSelfDescribingEvent", {
		event: {
			schema: "iglu:io.aiven/web_interaction/jsonschema/1-0-0",
			data: {
				object: analyticsIdF(analyticsId, "pgplayground"),
				action: "execute-playground-query",
			},
		},
		context: [
			{
				schema: "iglu:io.aiven/tools_sql_syntax_check/jsonschema/1-0-0",
				data: {
					query_length: queryLength,
				},
			},
		],
	});
}

export function trackSqlCompareSession({
	analyticsId,
	startedAt,
	endedAt,
	totalDiffUpdates,
	databaseType,
	databaseVersion,
}: {
	analyticsId: string;
	startedAt: string;
	endedAt: string;
	totalDiffUpdates: number;
	databaseType: string;
	databaseVersion: string;
}) {
	if (!window.snowplow) return;

	return window.snowplow("trackSelfDescribingEvent", {
		event: {
			schema: "iglu:io.aiven/web_interaction/jsonschema/1-0-0",
			data: {
				object: analyticsIdF(analyticsId, "sql-compare-session"),
				action: "sql-compare-session",
				context: [
					{
						schema: "iglu:io.aiven/tools_sql_compare/jsonschema/1-0-0",
						started_at: startedAt,
						ended_at: endedAt,
						total_diff_updates: totalDiffUpdates,
					},
					{
						schema: "iglu:io.aiven/tools_db_selection/jsonschema/1-0-0",
						database_type: databaseType,
						database_version: databaseVersion,
					},
				],
			},
		},
	});
}

export function spTrackValueCalculator({
	employees,
	dbas,
	developers,
	infra_cost,
}: {
	employees: string;
	dbas: number;
	developers: number;
	infra_cost: string;
}) {
	if (!window.snowplow) return;

	return window.snowplow("trackSelfDescribingEvent", {
		event: {
			schema: "iglu:io.aiven/web_interaction/jsonschema/1-0-0",
			data: {
				object: "value calculator",
				action: "input",
				value: "infrastructure cost",
			},
		},
		context: [
			{
				schema: "iglu:io.aiven/value_calculator/jsonschema/1-0-0",
				data: {
					employees: employees,
					dbas: dbas,
					developers: developers,
					infra_cost: infra_cost,
				},
			},
		],
	});
}

export function spTrackQualified() {
	if (!window.qualified) return;

	return window.qualified(
		"handleEvents",
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		function (eventName: string, data: any) {
			window.snowplow("trackSelfDescribingEvent", {
				event: {
					schema: "iglu:io.aiven/qualified_interaction/jsonschema/1-0-0",
					data: {
						conversation_id: data.bot_conversation_id,
						country_code: data.field_values.country_code,
						company: data.field_values.company,
						employee_count: data.field_values.cb_employee_count,
						email: data.field_values.email,
						name: data.field_values.name,
						cta: data.field_values.qf_ctas,
						account_type: data.field_values.account_type,
						action: eventName,
					},
				},
			});

			// use in GTM to trigger marketing conversion pixel
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "Qualified",
				action: eventName,
			});
		}
	);
}

export function spTrackVideo({
	state,
	title,
	url,
	duration,
	currentTime,
	percentage,
}: {
	state: string;
	title: string;
	url: string;
	duration: number;
	currentTime: number;
	percentage: number;
}) {
	if (!window.snowplow) return;

	return window.snowplow("trackSelfDescribingEvent", {
		event: {
			schema: "iglu:io.aiven/web_interaction/jsonschema/1-0-0",
			data: {
				object: "video",
				action: state,
			},
		},
		context: [
			{
				schema: "iglu:io.aiven/video/jsonschema/1-0-0",
				data: {
					title: title,
					url: url,
					duration: duration,
					current_time: currentTime,
					percentage: percentage,
				},
			},
		],
	});
}

export function spTrackPricing({
	selector,
	service,
	cloud,
	region,
	configuration = {},
	addOns = [],
}: {
	selector: "service" | "cloud" | "region" | "addon" | "configuration";
	service: string;
	cloud: string;
	region: string;
	addOns?: string[];
	configuration?: {
		dedicated_vm?: number;
		cpu_per_vm?: number;
		ram_per_vm?: number;
		total_storage?: number;
		shard_count?: number;
	};
}) {
	if (!window.snowplow) return;

	return window.snowplow("trackSelfDescribingEvent", {
		event: {
			schema: "iglu:io.aiven/web_interaction/jsonschema/1-0-0",
			data: {
				object: "pricing inquiry",
				action: "select",
				value: selector,
			},
		},
		context: [
			{
				schema: "iglu:io.aiven/pricing_inquiry/jsonschema/2-0-1",
				data: {
					service: service,
					cloud: cloud,
					region: region,
					add_on: addOns,
					...configuration,
				},
			},
		],
	});
}

export function spTrackKpf({
	label,
	dv_messages,
	dv_size,
	incoming_dv,
	retention,
	retention_unit,
	replication,
}: {
	label: string;
	dv_messages: number;
	dv_size: number;
	incoming_dv: number;
	retention: number;
	retention_unit: string;
	replication: number;
}) {
	if (!window.snowplow) return;

	return window.snowplow("trackSelfDescribingEvent", {
		event: {
			schema: "iglu:io.aiven/web_interaction/jsonschema/1-0-0",
			data: {
				object: "kafka plan finder",
				action: "input",
				value: label,
			},
		},
		context: [
			{
				schema: "iglu:io.aiven/kafka_plan_finder/jsonschema/1-0-0",
				data: {
					dv_messages: dv_messages,
					dv_size: dv_size,
					incoming_dv: incoming_dv,
					retention: retention,
					retention_unit: retention_unit,
					replication: replication,
				},
			},
		],
	});
}

export function spTrackLink({
	url,
	text,
	position,
}: {
	url: string;
	text: string;
	position?: string;
}) {
	if (!window.snowplow) return;

	return window.snowplow("trackLinkClick", {
		targetUrl: url,
		elementTarget: position,
		elementContent: text,
	});
}

export function spTrackFeatureFlag({
	featureFlagName,
	experimentVariant,
}: {
	featureFlagName: string;
	experimentVariant: string;
}) {
	if (!window.snowplow) return;

	return {
		schema: "iglu:io.aiven/feature_flag/jsonschema/1-0-0",
		data: {
			name: featureFlagName,
			variant: experimentVariant,
		},
	};
}

export function spTrackToolsFeedback(data: SqlToolFeedbackSnowplowData) {
	if (!window.snowplow) return;

	return window.snowplow("trackSelfDescribingEvent", {
		event: {
			schema: "iglu:io.aiven/tools_sql_user_feedback/jsonschema/1-0-0",
			data: data,
		},
	});
}

export const analyticsIdF = (
	descOfElementLocation: string,
	descOfTheElementItself: string
): AnalyticsId => `${descOfElementLocation}--${descOfTheElementItself}`;
