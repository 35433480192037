import clsx from "clsx";
import ReactMarkdown, { Components } from "react-markdown";
import type {
	CodeProps,
	Element,
	HeadingProps,
} from "react-markdown/lib/ast-to-react";
import React, { HTMLAttributes, ReactNode } from "react";
import rehypeSlug from "rehype-slug";
import remarkGfm from "remark-gfm";
import remarkDirective from "remark-directive";
import remarkDirectiveRehype from "remark-directive-rehype";
import remarkRemoveComments from "remark-remove-comments";
import remarkBreaks from "remark-breaks";
import remarkMath, { type Options as MathOptions } from "remark-math";
import rehypeKatex from "rehype-katex";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import type { AdsBannerProps } from "~/components/ads-banner";
import { AdsBanner } from "~/components/ads-banner";
import { MermaidClient as Mermaid } from "~/components/mermaid";
import { NumberItem, type NumberProps } from "~/components/number-list";
import { Alert } from "~/components/alert";
import { Image } from "~/components/ui/image";
import { Table } from "~/components/ui/table";
import {
	Blockquote,
	H2,
	H3,
	H4,
	H5,
	H6,
	Paragraph,
} from "~/components/ui/typography";
import { BulletList, OrderList, ListItem } from "~/components/list";
import {
	getImageBuilder,
	getImageProps,
	isSanityImgUrl,
	parseImgSrc,
} from "~/sanity/images";
import { Link } from "./ui/link";
import { asText } from "~/sanity/sanity-helpers";
import { Icon } from "./ui/icons";
import { Divider } from "./ui/divider";
import useScrollSpy from "~/hooks/useScrollSpy";
import { Youtube, YoutubeProps } from "./youtube";
import Quote from "~/images/shape-quote.svg";
import { spTrackWebInteraction } from "~/utils/tracking";
import { CopyToClipboardButton } from "./ui/CopyToClipboardButton";

type GenericElement<T> = HTMLAttributes<T> & {
	props?: {
		children?: ReactNode | ReactNode[];
	};
};

interface Props {
	// we need to remove Remix related
	//when rendering this component out side the Remix context
	shouldUseRemixRelatedContext?: boolean;
	content: string;
	withToc?: boolean;
	tocTitle?: string;
	tocTop?: string;
	fullWidth?: boolean;
}

function HeadingAnchorLink({ id }: { id: string }) {
	return (
		<a
			className="invisible absolute right-full top-0 flex h-full items-center px-2 group-hover:visible"
			aria-hidden="true"
			tabIndex={-1}
			href={`#${id}`}
			rel="nofollow"
			onClick={() =>
				spTrackWebInteraction({
					object: "heading",
					action: "anchor",
					value: `#${id}`,
				})
			}
		>
			<Icon width="16px" name="link" color="secondary" />
		</a>
	);
}

const heading = (node: Element, children: ReactNode, id?: string) => {
	switch (node.tagName) {
		case "h3":
			return (
				<H3 id={id} className="group relative mb-6 mt-7 first:mt-0">
					{id && <HeadingAnchorLink id={id} />}
					{children}
				</H3>
			);
		default:
			return (
				<H2 id={id} className="group relative mb-6 mt-9 first:mt-0">
					{id && <HeadingAnchorLink id={id} />}
					{children}
				</H2>
			);
	}
};

export function generateId(text: string) {
	return text.toLowerCase().replace(/[^a-z0-9]+/g, "-");
}

const remarkMathOptions: MathOptions = {
	/**
	 * Single dollar symbol to render inline epxressions in Math often interferes with “normal” dollars in text.
	 * We turn this off so we use two-dollar symbols ($$) instead
	 */
	singleDollarTextMath: false,
};

/**
 * remark-math only supports 2 dollar symbols for the math inline syntax. eg: $$ <math here> $$
 * However, github supports different syntax, starting the expression with $` and end it with `$. eg: $` <math here> `$
 *
 * Our editors prefer the Github syntax. We will support that.
 *
 * This function is to transform the Github syntax to the remark-math syntax.
 */
function fixInlineMathSyntax(content: string) {
	return content
		.replaceAll("$`", "$$$") // replace $`with $$
		.replaceAll("`$", "$$$"); // replace `$ with $$
}

export function MarkDown({
	content,
	shouldUseRemixRelatedContext = true,
	withToc = false,
	tocTitle,
	tocTop,
	fullWidth = false,
}: Props) {
	// https://gist.github.com/sobelk/16fe68ff5520b2d5e2b6d406e329e0de
	const toc: {
		level: number;
		id: string;
		title: string;
	}[] = [];

	const addToTOC = ({
		children,
		node,
	}: React.PropsWithChildren<HeadingProps>) => {
		// This function render headings with every styling it might have
		// For example a heading might have italic or underlined content in it
		// Tis function also adds the heading to the `toc` array and displays them on the side of the page
		const level = Number(node.tagName.match(/h(\d)/)?.slice(1));

		let headingString = "";

		for (const child of children) {
			if (typeof child === "string") {
				headingString += child;
			} else if (
				typeof child === "object" &&
				child !== null &&
				"props" in child &&
				(child.props as { children: string })?.children
			) {
				headingString += (child.props as { children: string }).children[0];
			}
		}

		if (level && headingString) {
			const id = generateId(headingString);
			if (!toc.some((content) => content.id === id)) {
				toc.push({
					level,
					id,
					title: headingString,
				});
			}
			return heading(node, children, id);
		} else {
			return heading(node, children);
		}
	};

	function TOC({ title }: { title?: string }) {
		const sectionIds = toc.map((item) => item.id);

		const activeSectionId = useScrollSpy({
			sectionIds: sectionIds,
			offsetPx: -150,
		});

		if (toc.length === 0) {
			return null;
		}

		return (
			<div
				className="lg:border-stroke hidden lg:sticky lg:block lg:h-[80vh] lg:w-[280px] lg:shrink-0 lg:self-start lg:overflow-auto lg:border-l lg:pl-6"
				style={{
					top: tocTop,
				}}
			>
				{title ? (
					<Paragraph size="overline" color="tagline" className="mb-5">
						{title}
					</Paragraph>
				) : null}
				<ul className="toc">
					{toc.map(({ level, id, title }) => (
						<ListItem
							key={id}
							// eslint-disable-next-line tailwindcss/no-custom-classname
							className={`toc-level-${level} !text-sm`}
							color="secondary"
						>
							<a
								href={`#${id}`}
								onClick={() =>
									spTrackWebInteraction({
										object: "toc",
										action: "anchor",
										value: `#${id}`,
									})
								}
								rel="nofollow"
								className={clsx(
									"underline-offset-4 hover:text-blue-70 hover:underline",
									{
										"font-semibold": id === activeSectionId,
									}
								)}
							>
								{title}
							</a>
						</ListItem>
					))}
				</ul>
			</div>
		);
	}

	const components = {
		banner: (props: AdsBannerProps) => {
			return (
				<AdsBanner
					{...props}
					shouldUseRegularAnchor={!shouldUseRemixRelatedContext}
				/>
			);
		},
		youtube: (props: YoutubeProps) => <Youtube {...props} />,
		alert: Alert,
		mermaid: Mermaid,
		statistic: (props: NumberProps) => {
			return (
				<NumberItem
					{...props}
					className="my-layout3 rounded-lg bg-theme-secondary p-9 text-center [&>p]:text-secondary-90"
				/>
			);
		},
		code: ({
			node,
			inline,
			className,
			children,
			style,
			...props
		}: CodeProps) => {
			const match = /language-(\w+)/.exec(className || "");

			const code = String(children).replace(/\n$/, "");

			// The key here will cause ts error
			// remove it from the props
			// eslint-disable-next-line react/prop-types
			const { key, ...rest } = props;

			return !inline ? (
				<div className="border-stroke relative my-5 border">
					<SyntaxHighlighter
						customStyle={{
							backgroundColor: "rgb(var(--color-grey-0))",
							margin: "0",
						}}
						codeTagProps={{
							className: "font-code",
						}}
						language={match ? match[1] : "plaintext"}
						PreTag="div"
						{...rest}
					>
						{code}
					</SyntaxHighlighter>
					<div className="absolute right-3 top-3">
						<CopyToClipboardButton value={code} />
					</div>
				</div>
			) : (
				<code
					className={clsx(
						className,
						"border-stroke my-1 inline-block border bg-grey-0 px-2 font-code text-grey-100"
					)}
					{...props}
				>
					{children}
				</code>
			);
		},
		h1: addToTOC,
		h2: addToTOC,
		h3: addToTOC,
		h4: ({ id, children }: { id: string; children: ReactNode }) => (
			<H4 id={id} className="group relative mb-6 mt-3">
				{id && <HeadingAnchorLink id={id} />}
				{children}
			</H4>
		),
		h5: ({ id, children }: { id: string; children: ReactNode }) => (
			<H5 id={id} className="group relative mb-6 mt-3">
				{id && <HeadingAnchorLink id={id} />}
				{children}
			</H5>
		),
		h6: ({ id, children }: { id: string; children: ReactNode }) => (
			<H6 id={id} className="group relative mb-6 mt-3">
				{id && <HeadingAnchorLink id={id} />}
				{children}
			</H6>
		),
		p: ({ children }: { children: ReactNode }) => {
			return (
				<Paragraph
					whiteSpacePreLine={false}
					className="mb-5 first-of-type:mt-0"
				>
					{children}
				</Paragraph>
			);
		},
		ul: ({ children }: { children: ReactNode }) => (
			<BulletList className="mb-5">{children}</BulletList>
		),
		ol: ({ children }: { children: ReactNode }) => (
			<OrderList className="mb-5">{children}</OrderList>
		),
		li: ({ children, id }: { id: string; children: ReactNode }) => (
			<ListItem id={id}>{children}</ListItem>
		),
		a: ({
			children,
			href,
			id,
		}: {
			id: string;
			children: ReactNode;
			href: string;
		}) => {
			return (
				<Link
					id={id}
					className="text-link"
					to={asText(href)}
					trackingPosition="text"
					forceUseAnchorLink={!shouldUseRemixRelatedContext}
				>
					{children}
				</Link>
			);
		},
		img: ({ src, alt, title }: { src: string; alt: string; title: string }) => {
			const imgSrc = parseImgSrc(src);
			let validImgSrc = imgSrc;
			// remove default width from Sanity drag&drop
			if (imgSrc.endsWith("?w=450")) {
				validImgSrc = imgSrc.replace("?w=450", "");
			}

			const imgProps = isSanityImgUrl(validImgSrc)
				? getImageProps(
						getImageBuilder(validImgSrc, {
							alt,
						}),
						{
							widths: [400, 600, 800, 1000],
							sizes: ["(min-width:1024px) 600px", "100vw"],
							title,
						}
					)
				: { src: validImgSrc, alt, title };

			return <Image className="!inline-block" src={src} {...imgProps} />;
		},
		blockquote: ({ children }: { children: ReactNode }) => (
			<Blockquote className="relative my-8 !whitespace-normal rounded-lg bg-theme-secondary p-9 text-center [&>p>em]:not-italic [&>p]:my-0 [&>p]:text-lg [&>p]:text-secondary-90">
				<Image
					className="inline pb-6"
					width="44px"
					height="33px"
					src={Quote}
					alt=""
				/>
				{children}
			</Blockquote>
		),
		table: ({ children }: { children: ReactNode }) => {
			const [head, body] = React.Children.toArray(children);
			const headElement = head as GenericElement<HTMLTableRowElement>;
			const bodyElement = body as GenericElement<HTMLTableRowElement>;
			return (
				<div className="overflow-x-auto">
					<Table className="my-layout4 min-w-[640px] table-auto text-left">
						<Table.Head className="align-top">
							{headElement?.props?.children ?? null}
						</Table.Head>
						<Table.Body>{bodyElement?.props?.children ?? null}</Table.Body>
					</Table>
				</div>
			);
		},
		tr: ({ children }: { children: ReactNode }) => (
			<Table.Row>{children}</Table.Row>
		),
		td: ({ children }: { children: ReactNode }) => (
			<Table.Cell>{children}</Table.Cell>
		),
		th: ({ children }: { children: ReactNode }) => (
			<Table.Cell>{children}</Table.Cell>
		),
		hr: () => <Divider size="layout3" />,
	};

	return (
		<div
			className={clsx({
				"lg:flex lg:gap-6": withToc,
			})}
		>
			<div
				className={clsx("markdown-prose", {
					"lg:grow": withToc,
				})}
			>
				<div
					data-testid="markdown"
					className={clsx({
						"max-w-content": !fullWidth,
					})}
				>
					<ReactMarkdown
						components={components as Components}
						children={fixInlineMathSyntax(content)}
						rehypePlugins={[rehypeSlug, rehypeKatex]}
						remarkPlugins={[
							[remarkMath, remarkMathOptions],
							remarkBreaks,
							remarkGfm,
							remarkDirective,
							remarkDirectiveRehype,
							remarkRemoveComments,
						]}
					/>
				</div>
			</div>
			{withToc ? <TOC title={tocTitle} /> : null}
		</div>
	);
}
