import { analyticsIdF, spTrackWebInteraction } from "~/utils/tracking";
import { IconButton } from "~/components/ui/button";
import { ReactNode, useState } from "react";
import { IconName } from "~/components/ui/icons";
import { useCopyToClipboard } from "usehooks-ts";

type CopyButtonIcon = Extract<IconName, "copy" | "check" | "link">;

type Props = {
	value: string;
	label?: string;
	isLink?: boolean;
	analyticsId?: string;
	disabled?: boolean;
	className?: string;
	children?: ReactNode;
};

function CopyToClipboardButton({
	label,
	value,
	analyticsId,
	disabled = false,
	className,
	isLink = false,
	children,
}: Props) {
	const icon = isLink ? "link" : "copy";
	const cx = isLink
		? ` flex h-5 items-center px-2 text-grey-60 group-hover:visible ${className}`
		: "h-6";
	const [copyIcon, setCopyIcon] = useState<CopyButtonIcon>(icon);
	const [statusMessage, setStatusMessage] = useState<string | null>(null);
	const [_copiedValue, copy] = useCopyToClipboard();

	const handleClick = async () => {
		copy(value)
			.then(() => {
				setCopyIcon("check");
				setStatusMessage("Copied!");

				setTimeout(() => {
					setCopyIcon(icon);
					setStatusMessage(null);
				}, 1000);

				if (analyticsId) {
					spTrackWebInteraction({
						object: "button",
						action: "click",
						value: analyticsIdF(analyticsId, "copy-to-clipboard-clicked"),
					});
				} else {
					spTrackWebInteraction({
						object: "code",
						action: "copy",
						value: value,
					});
				}
			})
			.catch((error) => {
				console.error("Failed to copy!", error);
			});
	};

	return (
		<>
			<IconButton
				name={label ? label : "Copy to clipboard"}
				iconName={copyIcon}
				disabled={disabled}
				className={cx}
				onClick={handleClick}
				iconHeight={isLink ? "11" : "24"}
				iconWidth={isLink ? "16" : "24"}
			>
				{children}
			</IconButton>
			<span
				role="status"
				aria-label={statusMessage || ""}
				aria-live="assertive"
				className="sr-only"
			/>
		</>
	);
}

export { CopyToClipboardButton };
