import { ReactNode, type JSX } from "react";
import * as RadixTooltip from "@radix-ui/react-tooltip";
import clsx from "clsx";

type TooltipProps = {
	label: ReactNode;
	children: JSX.Element;
};

export default function Tooltip({ label, children }: TooltipProps) {
	return (
		<RadixTooltip.Provider delayDuration={100}>
			<RadixTooltip.Root>
				<RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>

				<RadixTooltip.Portal>
					<RadixTooltip.Content
						side="top"
						align="center"
						sideOffset={8}
						className={clsx(
							"z-50 rounded bg-grey-90 px-4 py-3 text-xs text-white shadow-md [&_p]:text-xs [&_p]:text-white",
							"data-[state=delayed-open]:animate-fadeInScale",
							"data-[state=closed]:animate-fadeOutScale"
						)}
					>
						{label}
						<RadixTooltip.Arrow className="fill-grey-90" />
					</RadixTooltip.Content>
				</RadixTooltip.Portal>
			</RadixTooltip.Root>
		</RadixTooltip.Provider>
	);
}
