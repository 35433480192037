import clsx from "clsx";
import { ForwardedRef, forwardRef } from "react";
import type { CustomArrowProps, Settings } from "react-slick";
import Slider from "react-slick";
import { Icon } from "../ui/icons";

type CarouselArrowProps = CustomArrowProps & {
	direction: "left" | "right";
};

const CarouselArrow = (props: CarouselArrowProps) => {
	const { className, onClick, direction } = props;
	return (
		<button className={clsx("h-full", className)} onClick={onClick}>
			<Icon
				name={direction === "left" ? "chevron-left" : "chevron-right"}
				color="secondary"
				width="16"
				height="16"
			/>
		</button>
	);
};

const Carousel = (
	{
		slidesToShow = 1,
		slidesToScroll = 1,
		children,
		dots = true,
		className,
		arrows = true,
		variableWidth = false,
		centerMode = false,

		...rest
	}: Settings,
	ref: ForwardedRef<Slider>
) => {
	return (
		<Slider
			ref={ref}
			className={className}
			dots={dots}
			arrows={arrows}
			adaptiveHeight
			slidesToShow={slidesToShow}
			slidesToScroll={slidesToShow}
			centerMode={centerMode}
			nextArrow={<CarouselArrow direction="right" />}
			prevArrow={<CarouselArrow direction="left" />}
			variableWidth={variableWidth}
			responsive={[
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 1,
						slidesToScroll: slidesToScroll,
					},
				},
			]}
			{...rest}
		>
			{children}
		</Slider>
	);
};

export default forwardRef(Carousel);
