import { keyBy, mapValues } from "lodash-es";
import { validateLanguage } from "./language";
import { MetaArgs, type HeadersFunction } from "react-router";
import { getSocialMetas } from "~/utils/seo";
import {
	getImageAltProp,
	getImageBuilder,
	getImageProps,
} from "~/sanity/images";
import { getStructuredData } from "~/utils/schemas";
import type { PageData } from "~/types";
import type { Language } from "./language";
import devCenterMetaImage from "~/images/devcenter-meta-preview.jpg";
import pageMetaImage from "~/images/aiven-meta-preview.jpg";
import { externalLinks } from "./external-links";
import invariant from "tiny-invariant";
import { getPostHogClient } from "./posthog-client";
import { v4 as uuidv4 } from "uuid";
import { ZodError } from "zod";
import { hasProperty } from "./types";
import type { PostDocument, SectionContent } from "~/types/sanity-schema";
import * as ct from "countries-and-timezones";
import { RootLoaderData } from "~/root";
import { getDefaultPageCachingHeaders } from "./caching";

export const IS_BROWSER = typeof window !== "undefined";

const chainableFunctions = {
	keyBy,
	mapValues,
};

// https://github.com/lodash/lodash/issues/3298
function chain<T>(input: T) {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let value: any = input;
	const wrapper = {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		...mapValues(chainableFunctions, (f: any) => (...args: any[]) => {
			value = f(value, ...args);
			return wrapper;
		}),
		value: () => value,
	};
	return wrapper;
}

export function isDevelopment() {
	return process.env.NODE_ENV === "development";
}

/**
 * Transforms a single slug into an array of its possible variations.
 *
 * As editors can include leading and/or trailing slashes in routes' slugs,
 * we need to normalize them before searching routes by slug.
 */
export function getSlugVariations(slug: string) {
	const slashless = slug.replace(/\//g, "");
	return [
		slashless,
		// /slash-on-both-ends/
		`/${slashless}/`,
		// trailing/
		`${slashless}/`,
		// /leading
		`/${slashless}`,
	];
}

export function getDomainUrl(request: Request) {
	const host =
		request.headers.get("X-Forwarded-Host") ?? request.headers.get("host");
	if (!host) {
		throw new Error("Could not determine domain URL.");
	}
	const protocol = host.includes("localhost") ? "http" : "https";
	return `${protocol}://${host}`;
}

export function removeTrailingSlash(s: string) {
	return s.endsWith("/") ? s.slice(0, -1) : s;
}

export function removeForwardSlash(s: string) {
	return s.replace(/\/+/, "");
}

export function removeLangCode(s: string) {
	const [, urlLang] = s.split("/", 2);

	if (validateLanguage(urlLang)) {
		return s.replace(`/${urlLang}`, ``);
	}

	return s;
}

export function getOrigin(requestInfo?: { origin?: string; path: string }) {
	return requestInfo?.origin ?? "https://aiven.io";
}

export function getUrl(requestInfo?: { origin: string; path: string }) {
	return removeTrailingSlash(
		`${getOrigin(requestInfo)}${requestInfo?.path ?? ""}`
	);
}

export function getPageUrl(
	requestInfo?: { origin: string; path: string },
	locale?: Language,
	slug?: { current: string }
): string {
	const baseOrigin = getOrigin(requestInfo);
	const prefix = locale && locale !== "en" ? `/${locale}` : "";
	return `${baseOrigin}${prefix}${slug?.current}`;
}

export function getReferenceUrl(
	locale?: Language,
	slug?: { current: string }
): string {
	const prefix = locale && locale !== "en" ? `/${locale}` : "";
	return `${prefix}${slug?.current}`;
}

export function formatDate(
	dateInput: string | Date,
	locale?: Language
): string {
	const date = typeof dateInput === "string" ? new Date(dateInput) : dateInput;
	return date.toLocaleString(locale || "en", { dateStyle: "medium" });
}

export function formatTime(
	dateString: string,
	options?: { showTimeZone: boolean }
): string {
	const date = new Date(dateString);

	const { showTimeZone = false } = options ?? {};

	// British English uses 24-hour time
	return date.toLocaleTimeString("en-GB", {
		hour: "2-digit",
		minute: "2-digit",
		timeZoneName: showTimeZone ? "short" : undefined,
	});
}

export function getYearFromDate(dateString: string): number {
	const date = new Date(dateString);
	return date.getFullYear();
}

export const getUrlParam = (name: string): null | string => {
	if (!IS_BROWSER) {
		return null;
	}

	try {
		const params = new URLSearchParams(location.search);
		return params.get(name);
	} catch (_error) {
		return null;
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isPageData(pageData: any): pageData is PageData {
	if (!pageData) return false;

	return (
		"query" in pageData && "queryParams" in pageData && "isPreview" in pageData
	);
}

type ModifiedMetaArgs = Partial<Omit<MetaArgs, "location">> & {
	location: globalThis.Location;
};

export function reuseSeoMeta({ data, matches, location }: ModifiedMetaArgs) {
	const rootData = matches?.find((m) => m.id === "root")
		?.data as RootLoaderData;

	const { requestInfo } = rootData;

	const defaultMetaData = getSocialMetas({
		origin: getOrigin(requestInfo),
		url: getUrl(requestInfo),
	});

	const { pathname } = location;
	const isDeveloperCenterPage = pathname.includes("/developer");

	const defaultMetaImage = isDeveloperCenterPage
		? devCenterMetaImage
		: pageMetaImage;

	// check if data is an object and has pageData
	if (!data) {
		return [{ title: "404 - Page Not Found" }];
	}

	if (typeof data !== "object") return defaultMetaData;
	if (!("pageData" in data)) return defaultMetaData;

	const { pageData } = data;

	// check the type of value of page data
	if (!isPageData(pageData)) {
		return defaultMetaData;
	}

	const seo = pageData.data?.seo;

	// check if seo data exits
	if (!seo) {
		return defaultMetaData;
	}

	const imageProps = getImageProps(
		getImageBuilder(seo?.metaImage, {
			alt: getImageAltProp(seo?.metaImage),
		})
			?.width(1200)
			.height(630)
	);

	const socialMetas = getSocialMetas({
		origin: getOrigin(requestInfo),
		title: seo?.metaTitle,
		url: getUrl(requestInfo),
		description: seo?.metaDescription,
		image: imageProps.src
			? imageProps
			: {
					src: `${removeTrailingSlash(
						getOrigin(requestInfo)
					)}${defaultMetaImage}`,
				},
		locale: pageData.data?.__i18n_lang,
	});

	// Structured Data
	const metaStructuredData = getStructuredData({
		baseUrl: getOrigin(requestInfo),
		pageData: pageData.data,
	});

	return metaStructuredData
		? [metaStructuredData, ...socialMetas]
		: socialMetas;
}

export const reuseHeaders: HeadersFunction = () => {
	return getDefaultPageCachingHeaders();
};

export function mapSanityDictionary(
	dictionary: Array<{
		name: string;
		value: string;
	}>
) {
	return chain(dictionary).keyBy("name").mapValues("value").value();
}

export function fixLineBreaks(val: string) {
	return val?.replace("\\n", "\n");
}

export function notEmpty<TValue>(
	value: TValue | null | undefined
): value is TValue {
	return value !== null && value !== undefined;
}

export function isGradientColor(color: string) {
	return /gradient/g.test(color);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setItemLocalStorage(key: string, data: any) {
	localStorage.setItem(key, JSON.stringify(data));
}

export function getItemLocalStorage(key: string) {
	try {
		const item = localStorage.getItem(key);
		if (item) {
			return JSON.parse(item);
		}
	} catch (error) {
		console.error("Failed to read from localStorage:", error);
	}
}

export function capitalize(s: string) {
	return s.charAt(0).toUpperCase() + s.slice(1);
}

// https://stackoverflow.com/a/1408373/8737224
// http://www.java2s.com/ref/javascript/javascript-string-supplant-obj.html
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function supplant(text: string, obj: Record<string, any>) {
	function replacer(_: string, name: string) {
		const value = obj[name];
		const type = typeof value;

		if (value instanceof Array) return value.toString();
		if (type === "string") return value;
		if (type === "number") return value;

		return name;
	}

	return text.replace(/{([^{}]*)}/g, replacer);
}

export function getCookieValue(name: string): string | null {
	const matches = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");

	if (!matches) return null;
	const encodedValue = matches[2];

	if (!isValidURI(encodedValue)) return null;

	return decodeURIComponent(encodedValue);
}

export function isExternalUrl(url: string) {
	const isRelativeLink = /^https?:\/\//i.test(url);

	if (!isRelativeLink) {
		return false;
	}

	const origin = new URL(url).origin;

	return origin !== externalLinks.rootDomain;
}

export function sanitizeInput(input: string) {
	// Remove any potentially malicious code
	return input.replace(/[^a-zA-Z0-9 .,?!@#$%^&*()_+-=;:'"|\\/]/g, "");
}

export function invariantResponse(
	condition: boolean,
	message: string
): asserts condition {
	try {
		invariant(condition, message);
	} catch (_e) {
		throw new Response(message, { status: 400 });
	}
}

export async function getPostHogData(
	request: Request,
	featureFlagName: string
) {
	const cookieString = request.headers.get("Cookie") || "";
	const projectAPIKey = ENV.POSTHOG_PUBLIC_KEY;
	const cookieName = `ph_${projectAPIKey}_posthog`;
	const cookieMatch = cookieString.match(new RegExp(cookieName + "=([^;]+)"));
	let distinctId;
	let isFeatureEnabled;
	let variant;

	if (cookieMatch && Boolean(cookieMatch)) {
		const parsedValue = JSON.parse(decodeURIComponent(cookieMatch[1]));
		if (parsedValue && parsedValue.distinct_id) {
			distinctId = parsedValue.distinct_id;
		} else {
			distinctId = uuidv4();
		}
	} else {
		distinctId = uuidv4();
	}

	const phClient = getPostHogClient();

	if (phClient) {
		isFeatureEnabled = await phClient.isFeatureEnabled(
			featureFlagName,
			distinctId,
			{
				sendFeatureFlagEvents: false,
			}
		);
		variant = await phClient.getFeatureFlag(featureFlagName, distinctId, {
			sendFeatureFlagEvents: false,
		});
	}

	return { distinctId, isFeatureEnabled, variant };
}

export function parseMessageFromZodError(
	error: unknown,
	fallbackMessage = "Invalid data"
): Promise<{ status: number; message: string }> {
	if (error instanceof ZodError) {
		if (
			Array.isArray(error.errors) &&
			error.errors.length > 0 &&
			hasProperty(error.errors[0], "message") &&
			typeof error.errors[0].message === "string"
		) {
			return Promise.resolve({
				status: 400,
				message: error.errors[0].message,
			});
		}
		return Promise.resolve({ status: 400, message: fallbackMessage });
	}
	throw error;
}

export const contentReferenceData = (content: SectionContent) => {
	return content
		.flatMap((contentItem) => {
			if ("items" in contentItem) {
				return contentItem.items
					?.map((item) => (item as PostDocument)._updatedAt)
					.filter(Boolean);
			}
			return undefined;
		})
		.filter((ar) => ar && ar.length > 0);
};

export function isValidURI(value: string): boolean {
	try {
		return decodeURIComponent(encodeURIComponent(value)) === value;
	} catch {
		return false;
	}
}

export function getUserCountry(): string {
	const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	try {
		return ct.getCountryForTimezone(timezone)?.name ?? "";
	} catch (error) {
		return "";
		console.error("Error getting country from timezone:", error);
	}
}
