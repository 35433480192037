import React from "react";
import mermaid from "mermaid";
import { ClientOnly } from "remix-utils/client-only";
import { onlyText } from "react-children-utilities";

type MermaidProps = {
	children: React.ReactNode & React.ReactNode[];
};

function Mermaid({ children }: { children: string }) {
	React.useEffect(() => {
		const renderDiagram = async () => {
			mermaid.initialize({
				startOnLoad: false,
			});
			await mermaid.run({
				querySelector: ".mermaid",
				suppressErrors: true,
			});
		};
		renderDiagram();
	}, [children]);

	if (!children) return null;

	return (
		//eslint-disable-next-line tailwindcss/no-custom-classname
		<div className="mermaid my-layout3" data-testid="mermaid">
			{children}
		</div>
	);
}

export function MermaidClient({ children }: MermaidProps) {
	const mermaidText =
		typeof children === "string"
			? children
			: onlyText(children).replace(/\bend/, "end;");

	return <ClientOnly>{() => <Mermaid children={mermaidText} />}</ClientOnly>;
}
