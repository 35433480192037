import clsx from "clsx";
import type { LinkProps } from "./link";
import { Link } from "./link";

import type { JSX } from "react";

export type IconLinkProps = {
	iconLeft?: JSX.Element;
	iconRight?: JSX.Element;
};

export function IconLink({
	children,
	prefetch = "intent",
	className,
	to,
	color,
	iconLeft,
	iconRight,
	...rest
}: IconLinkProps & LinkProps) {
	return (
		<Link
			prefetch={prefetch}
			to={to}
			className={clsx(
				"text-primary hover-underline-expand inline-flex w-fit items-center gap-2 rounded border border-transparent bg-transparent font-medium no-underline focus-visible:outline-blue-40 disabled:text-grey-30",
				className
			)}
			{...rest}
		>
			{iconLeft ? iconLeft : null}
			<span data-label="link-title">{children}</span>
			{iconRight ? iconRight : null}
		</Link>
	);
}
