import clsx from "clsx";
import { Link as RemixLink } from "react-router";
import type { LinkProps as RemixLinkProps } from "react-router";
import { spTrackLink } from "~/utils/tracking";
import { scrollIntoViewOffset } from "~/utils/hooks";

export interface LinkProps extends RemixLinkProps {
	trackingText?: string;
	trackingPosition?: string;
	forceUseAnchorLink?: boolean;
}

export function Link({
	children,
	trackingText,
	trackingPosition,
	prefetch = "intent",
	to,
	className,
	reloadDocument,
	forceUseAnchorLink = false,
	...rest
}: LinkProps) {
	let toUrl = "";

	function handleOnClick(e: React.MouseEvent<HTMLAnchorElement>) {
		spTrackLink({
			url: e.currentTarget.href,
			text: trackingText || e.currentTarget.text,
			position: trackingPosition,
		});
	}

	let shouldUseRegularAnchor = false;

	if (typeof to === "string") {
		toUrl = to;
		shouldUseRegularAnchor =
			toUrl.startsWith("http") ||
			toUrl.startsWith("mailto") ||
			toUrl.startsWith("?q_showExperience");
	}

	if (typeof to === "object") {
		toUrl = `${to.pathname ?? ""}${to.hash ? `#${to.hash}` : ""}${
			to.search ? `?${to.search}` : ""
		}`;

		shouldUseRegularAnchor = to.pathname?.startsWith("http") || true;
	}

	if (forceUseAnchorLink || shouldUseRegularAnchor) {
		return (
			<a className={className} href={toUrl} onClick={handleOnClick} {...rest}>
				{children}
			</a>
		);
	}

	// Prevent default Remix navigation for hash links to avoid triggering a page reload and losing loader data from useMatches
	if (toUrl.startsWith("#")) {
		const handleScrollToSection = (e: React.MouseEvent<HTMLAnchorElement>) => {
			e.preventDefault();

			const element = document.getElementById(
				e.currentTarget.href.split("#")[1]
			);

			if (element) {
				scrollIntoViewOffset(element, 100); // 100px offset from top nav
			}
		};

		return (
			<a
				className={className}
				href={toUrl}
				onClick={(e) => {
					handleOnClick(e);
					handleScrollToSection(e);
				}}
				{...rest}
			>
				{children}
			</a>
		);
	}

	return (
		<RemixLink
			className={className}
			prefetch={prefetch}
			reloadDocument={reloadDocument}
			to={to}
			onClick={handleOnClick}
			{...rest}
		>
			{children}
		</RemixLink>
	);
}

interface ConditionalLinkProps extends LinkProps {
	condition: boolean;
	as?: React.ElementType;
}

export const ConditionalLink: React.FunctionComponent<ConditionalLinkProps> = ({
	children,
	to,
	condition,
	className,
	as,
	...rest
}: ConditionalLinkProps) => {
	const Tag = as ?? "div";

	return condition ? (
		<Link to={to} className={clsx(className)} {...rest}>
			{children}
		</Link>
	) : (
		<Tag className={className}>{children}</Tag>
	);
};
